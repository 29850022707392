export default {
  data() {
    return {
      MIXINS_DATA: {
        identifier: {
          mortgageAccountId: this.$store.getters[
            "get_identifier_mortgage_account_id"
          ],
        },
      },
    };
  },
  computed: {
    mixin_data_identifier_mortgage_account_id() {
      return this.$store.getters["get_identifier_mortgage_account_id"];
    },
    mixin_data_identifier_mortgage_account_data() {
      return this.$store.getters["get_identifier_mortgage_account_data"];
    },
    mixin_data_options_mortgage_collateral_precious_metal_mortgage_account() {
      return this.$store.getters[
        "get_options_mortgage_account_mortgage_collateral_precious_metal"
      ];
    },
    mixin_data_options_mortgage_document_checklist_mortgage_account() {
      return this.$store.getters[
        "get_options_mortgage_account_mortgage_document_checklist"
      ];
    },
  },

  methods: {
    async MIXINS_METHOD_getRefferenceMortgageAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "mortgage-account/" +
              this.$store.getters["get_identifier_mortgage_account_id"],
          });
          if (resp.data.code === "SUCCESS") {
            //   var loanInstallment = [];
            var mortgageCollateralPreciousMetal = [];
            var mortgageDocumentChecklist = [];
            mortgageCollateralPreciousMetal = [
              { value: "", text: "-- Pilih --" },
            ];
            mortgageCollateralPreciousMetal.push({
              text: `${resp.data.data.mortgageAccountNumber} - Kontrak Utama`,
              value: resp.data.data.mortgageAccountNumber,
              mortgageAccountNumber: resp.data.data.mortgageAccountNumber,
              mortgageAccountId: resp.data.data.mortgageAccountId,
              mortgageRegisterNumber:
                resp.data.data.mortgageRegisterId.mortgageRegisterNumber,
            });
            mortgageDocumentChecklist = [{ value: "", text: "-- Pilih --" }];
            mortgageDocumentChecklist.push({
              text: `${resp.data.data.mortgageAccountNumber} - Kontrak Utama`,
              value: resp.data.data.mortgageAccountNumber,
              mortgageAccountNumber: resp.data.data.mortgageAccountNumber,
              mortgageAccountId: resp.data.data.mortgageAccountId,
            });
            this.$store.dispatch(
              "SET_IDENTIFIER_MORTGAGE_ACCOUNT_DATA",
              resp.data.data
            );
            this.$store.dispatch(
              "SET_OPTIONS_MORTGAGE_ACCOUNT_MORTGAGE_COLLATERAL_PRECIOUS_METAL",
              mortgageCollateralPreciousMetal
            );
            this.$store.dispatch(
              "SET_OPTIONS_MORTGAGE_ACCOUNT_MORTGAGE_DOCUMENT_CHECKLIST",
              mortgageDocumentChecklist
            );
            setTimeout(() => {
              console.log(this.MIXINS_DATA);
            }, 2000);
          }
        } catch (error) {}
      }
    },
  },
};
