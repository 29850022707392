import AddMortgageAccount from "./mortage-account";
import Mixins from "../Mixins.js";

export default {
  name: "AddTMortgageAccount",
  mixins: [Mixins],
  components: {
    AddMortgageAccount,
  },
  destroyed() {
    this.$store.dispatch("CLEAR_OPTIONS_MORTGAGE_ACCOUNT");
  },
  methods: {
    centralBackToList() {
      this.$router.push("/loan/mortgage-account");
    },
  },
};
