import Mixins from "../../Mixins.js";
import Moment from "moment";

export default {
  name: "AddMortgageAccount",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        cifId: "",
      },
      property: {
        animation: {
          addMortgageAccount: {
            isLoading: false,
          },
        },
      },
      identifier: {
        cifNumber: "",
        cifCategoryName: "",
        cifIdName: "",
        cifOfficeName: "",
        timeType: "",
        mortgageRegisterNumber: "",
        brutoTotal: 0,
        netWeightTotal: 0,
        slteTotal: 0,
        estimatePriceTotal: 0,
        rentPriceTotal: 0,
        maxLoanTotal: 0,
      },
      dataForm: {
        referalOfficeId: "",
        cifId: "",
        akadNumber: "",
        mortgageAccountNumber: "",
        loanAkadTypeId: "",
        loanId: "",
        akadDate: "",
        effectiveDate: "",
        timePeriod: 0,
        defiationMemoNumber: "",
        discountRentPercent: 0,
        dueDate: "",
        auctionDate: "",
        purposeOpenAccountId: "",
        estimatePrice: 0,
        accountOfficerCollectorId: "",
        accountOfficerHandleId: "",
        accountOfficerPromoId: "",
        dropingAccountId: "",
        feeRentPercent: 0,
        feeRentNominal: 0,
        feeAdmin: 0,
        mortgageRegisterId: "",
        feeMaterai: 0,
        feeTotal: 0,
        nominalMortgage: 0,
        nominalTotal: 0,
        suggestedNominalTotal: 0,
        sltePrice: 0,
        sltePriceInput: 0,
        tmortgageCollateralPreciousMetalRequestDtos: [],
      },
      options: {
        referalOffice: [],
        loanAkadType: [],
        loan: [],
        loanByAkad: [],
        purposeOpenAccount: [],
        collector: [],
        aoHandle: [],
        aoPromo: [],
        timePeriod: [],
        mortgageRegisterNumber: [],
        dropingAccount: [],
        mortgage: [],
      },
      table: {
        data: {
          tmortgageCollateralPreciousMetalRequestDtos: [],
        },
      },
    };
  },
  methods: {
    deleteMortgageCollateralPreciousMetal(props) {
      const findingUnSelected = this.table.data.tmortgageCollateralPreciousMetalRequestDtos.filter(
        (i) => i.id !== props.row.id
      );
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos = findingUnSelected;
      this.calculateTotalBrutoFooter();
      this.calculateTotalNetWeightFooter();
      this.calculateTotalSlteFooter();
      this.calculateTotalEstimatePriceFooter();
      this.calculateTotalRentPriceFooter();
      this.calculateTotalMaxLoanFooter();
    },
    addMortgageCollateralPreciousMetal() {
      const payload = {
        id: Math.random()
          .toString(36)
          .substr(2, 9),
        mortgage: {
          mortgageId: "",
          estimatePrice: 0,
          rentPricePercent: 0,
          sltePrice: 0,
          sltePriceInput: 0,
          rmortgageMetalType: {
            metalTypeName: "",
          },
          rmortgageCarat: {
            mortgageCaratName: "",
          },
          maxLoanPercent: 0,
        },
        quantity: 0,
        bruto: 0,
        netWeight: 0,
        totalEstimate: 0,
        rentPrice: 0,
        maxLoan: 0,
      };
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.push(payload);
    },
    changeEffectiveDate() {
      this.dataForm.dueDate = Moment(new Date(this.dataForm.effectiveDate))
        .add(this.dataForm.timePeriod, "M")
        .format();
      this.dataForm.auctionDate = Moment(new Date(this.dataForm.dueDate))
        .add(10, "days")
        .format();
    },
    changeOptionsMortgageRegisterNumber() {
      this.options.mortgageRegisterNumber.map((index) => {
        if (index.value === this.dataForm.mortgageRegisterId) {
          this.identifier.mortgageRegisterNumber = index.mortgageRegisterNumber;
        }
      });
    },
    checkingMainId() {
      var query = this.$route.query.refId;
      if (!query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListMortgageAccount();
        return;
      }
      this.identity.cifId = JSON.parse(this.decryptBASE64(query)).cifId;
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeId;
            this.options.loanAkadType.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferencePurposeOpenAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "purpose-open-account",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.purposeOpenAccountName;
            const value = index.purposeOpenAccountId;
            this.options.purposeOpenAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceMortgage() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_LOAN",
          {
            url: "mortgage/active-data",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((data) => {
            const text = `${data.mortgageCode} - ${data.mortgageName}`;
            const value = data.mortgageId;
            this.options.mortgage.push({
              text,
              value,
              mortgage: data,
            });
          });
        }
      } catch (error) {}
    },
    changeOptionsMortgage(props) {
      const findingMortgage = this.options.mortgage.find(
        (i) => i.value === props.row.mortgage.mortgageId
      );
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          if (data.id === props.row.id) {
            data.mortgage = findingMortgage.mortgage;
            data.mortgage.sltePriceInput = data.mortgage.sltePrice;

            this.calculateTotalEstimatePerRow(props);
            this.calculateRentPricePerRow(props, "per-row");
            this.calculateMaxLoanPerRow(props, "per-row");
            this.calculateTotalSlteFooter();
          }
        }
      );
    },
    calculateTotalEstimatePerRow(props) {
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          console.log("maxLoanPercent => ", data);
          if (data.id === props.row.id) {
            data.totalEstimate =
              ((data.netWeight *
                parseFloat(data.quantity) *
                data.mortgage.sltePriceInput) /
                data.mortgage.maxLoanPercent) *
              100;
            this.calculateRentPricePerRow(props, "per-row");
            this.calculateMaxLoanPerRow(props, "per-row");
            this.calculateTotalNetWeightFooter();
            this.calculateTotalEstimatePriceFooter();
          }
        }
      );
    },
    calculateRentPricePerRow(props, type) {
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          switch (type) {
            case "per-row":
              if (data.id === props.row.id) {
                let totalEstimateXTimeperiod =
                  data.totalEstimate * this.dataForm.timePeriod;
                let rentPricePercent =
                  (data.mortgage.rentPricePercent * 10 -
                    this.dataForm.discountRentPercent * 10) /
                  10 /
                  100;
                data.rentPrice = totalEstimateXTimeperiod * rentPricePercent;
              }
              break;
            case "all-row": {
              let totalEstimateXTimeperiod =
                data.totalEstimate * this.dataForm.timePeriod;
              let rentPricePercent =
                (data.mortgage.rentPricePercent * 10 -
                  this.dataForm.discountRentPercent * 10) /
                10 /
                100;
              data.rentPrice = totalEstimateXTimeperiod * rentPricePercent;
              break;
            }
            default:
              break;
          }
          this.calculateTotalRentPriceFooter();
        }
      );
    },
    calculateMaxLoanPerRow(props, type) {
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          switch (type) {
            case "per-row":
              if (data.id === props.row.id) {
                data.maxLoan =
                  data.totalEstimate * (data.mortgage.maxLoanPercent / 100);
              }
              break;
            case "all-row":
              data.maxLoan =
                data.totalEstimate * (data.mortgage.maxLoanPercent / 100);
              100;
              break;
            default:
              break;
          }
          this.calculateTotalMaxLoanFooter();
        }
      );
    },
    async getReferenceLoan() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_LOAN",
          {
            url: "loan/simple-list",
            params: {
              loanName: "",
              loanCode: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          Promise.all(
            resp.data.data.content.map((index) => {
              const text = `${index.loanName} - ${index.loanCode}`;
              const value = index.loanId;
              const marginCalculate = index.loanMarginCalculateCode;
              const loanCode = index.loanCode;
              const loanAkadTypeId = index.loanAkadTypeId;
              const loanAkadTypeAcronym = index.loanAkadTypeAcronym;
              this.options.loan.push({
                text,
                value,
                marginCalculate,
                loanAkadTypeId,
                loanAkadTypeAcronym,
                loanCode,
              });
            })
          ).then(() => {
            this.appendDefaultInput();
          });
        }
      } catch (error) {}
    },
    // async getReferenceCollector() {
    //   try {
    //     const resp = await this.$store.dispatch(
    //       "GET_REFERENCE_FROM_USER_MANAGEMENT",
    //       {
    //         url: "v2/user/active-user",
    //         params: {
    //           page: 0,
    //         },
    //       }
    //     );
    //     if (resp.data.code === "SUCCESS") {
    //       resp.data.data.content.map((index) => {
    //         const text = `${index.profileSurename} - ${index.userNik}`;
    //         const value = index.userId;
    //         this.options.collector.push({ text, value });
    //       });
    //     }
    //   } catch (error) {}
    // },
    async getReferenceAccountOfficer() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/reference",
          payload: {
            jobTitle: "",
          },
        });
        console.log("AO => ", resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
            const value = index.accountOfficerId;
            this.options.aoPromo.push({ text, value });
            this.options.aoHandle.push({ text, value });
            this.options.collector.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceMortgageRegisterNumber() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_LOAN",
          {
            url: "mortgage-register-number/inventory/active-data/simple-list",
            params: {
              mortgageRegisterNumber: "",
              isUsed: false,
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.mortgageRegisterNumber}`;
            const value = index.mortgageRegisterId;
            const mortgageRegisterNumber = index.mortgageRegisterNumber;
            this.options.mortgageRegisterNumber.push({
              text,
              mortgageRegisterNumber,
              value,
            });
          });
        }
      } catch (error) {}
    },
    async getSavingAccountByCifId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListMortgageAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/cif-id/" + this.identity.cifId,
          payload: {
            isCrossOffice: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.mcif.cifIdName} - ${index.accountNumber} - ${index.msaving.savingName}`;
            const value = index.savingAccountId;
            this.options.dropingAccount.push({ text, value });
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    routeToPageListMortgageAccount() {
      this.$router.push("/loan/mortgage-account");
    },
    async getCifById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListMortgageAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "cif/" + this.identity.cifId,
        });
        if (resp.data.code === "SUCCESS") {
          this.identifier.cifNumber = resp.data.data.cifNumber;
          this.identifier.cifCategoryName =
            resp.data.data.categoryId.cifCategoryName;
          this.identifier.cifIdName = resp.data.data.cifIdName;
          this.identifier.cifOfficeName = resp.data.data.officeId.officeName;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListMortgageAccount(),
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.routeToPageListMortgageAccount(),
        });
      }
    },
    changeTypeAkadMortgageAccount() {
      const filterLoanByAkad = this.options.loan.filter(
        (index) => index.loanAkadTypeId === this.dataForm.loanAkadTypeId
      );
      this.dataForm.loanId = "";
      this.options.loanByAkad = [
        { value: "", text: "-- Pilih --" },
        ...filterLoanByAkad,
      ];
    },
    async getReferenceOfficeReferal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.referalOffice = resp.data.data;
          this.options.referalOffice.unshift({
            officeName: "-- Pilih --",
            officeId: "",
          });
        }
      } catch (error) {}
    },
    async addMortgageAccount() {
      var payload = {
        cifId: this.identity.cifId,
        loanAkadTypeId: this.dataForm.loanAkadTypeId,
        loanId: this.dataForm.loanId,
        akadDate: this.dataForm.akadDate,
        effectiveDate: this.dataForm.effectiveDate,
        timePeriod: this.dataForm.timePeriod,
        dueDate: this.dataForm.dueDate,
        auctionDate: this.dataForm.auctionDate,
        purposeOpenAccountId: this.dataForm.purposeOpenAccountId,
        estimatePrice: this.dataForm.estimatePrice,
        accountOfficerCollectorId: this.dataForm.accountOfficerCollectorId,
        accountOfficerHandleId: this.dataForm.accountOfficerHandleId,
        accountOfficerPromoId: this.dataForm.accountOfficerPromoId,
        mortgageRegisterId: this.dataForm.mortgageRegisterId,
        dropingAccountId: this.dataForm.dropingAccountId,
        feeRentPercent: this.dataForm.feeRentPercent,
        feeRentNominal: this.dataForm.feeRentNominal,
        feeAdmin: this.dataForm.feeAdmin,
        feeMaterai: this.dataForm.feeMaterai,
        feeTotal: this.dataForm.feeTotal,
        nominalMortgage: this.dataForm.nominalMortgage,
        nominalTotal: this.dataForm.nominalTotal,
        defiationMemoNumber: this.dataForm.defiationMemoNumber,
        discountRentPercent: this.dataForm.discountRentPercent,
        referalOfficeId: this.dataForm.referalOfficeId,
        tmortgageCollateralPreciousMetalRequestDtos: [],
      };
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var quantity = data.quantity;
          var netWeight = data.netWeight;
          var estimateValue = data.totalEstimate;
          var bruto = data.bruto;
          var rloanCollateralTypeId = this.fetchAppSession(
            "@vue-session/config-bundle"
          ).VUE_APP_LOAN_COLLATERAL_TYPE_GOLD_JEWERLY;
          var rloanCollateralBindTypeId = this.fetchAppSession(
            "@vue-session/config-bundle"
          ).VUE_APP_LOAN_COLLATERAL_BIND_TYPE_GADAI_ID;
          var carat = data.mortgage.rmortgageCarat.mortgageCaratCode;
          var rloanCollateralCategoryId = this.fetchAppSession(
            "@vue-session/config-bundle"
          ).VUE_APP_LOAN_COLLATERAL_CATEGORY_PRECIOUS_METAL;
          var mortgageId = data.mortgage.mortgageId;
          var sltePrice = data.mortgage.sltePriceInput;
          payload.tmortgageCollateralPreciousMetalRequestDtos.push({
            sltePrice,
            quantity,
            netWeight,
            estimateValue,
            bruto,
            carat,
            mortgageId,
            rloanCollateralTypeId,
            rloanCollateralBindTypeId,
            rloanCollateralCategoryId,
          });
        }
      );
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addMortgageAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "mortgage-account",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  this.$router.push(
                    `/loan/mortgage-account/${this.encryptBASE64(
                      resp.data.data.mortgageAccountId
                    )}`
                  );
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addMortgageAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    appendDefaultInput() {
      this.dataForm.loanAkadTypeId = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_RAHN_ID;
      this.changeTypeAkadMortgageAccount();
      // this.dataForm.loanId = this.fetchAppSession(
      //   "@vue-session/config-bundle"
      // ).VUE_APP_LOAN_PRODUCT;
    },
    async getReferenceTimePeriode() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "time-period",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.timePeriod = [{ value: 0, text: "-- Pilih --" }];
          resp.data.data.map((data) => {
            const value = data.timePeriodName;
            const text = `${data.timePeriodName} ${data.timeType}`;
            this.options.timePeriod.push({ text, value });
          });
        }
      } catch (error) {}
    },
    changeTimePeriod() {
      this.changeEffectiveDate();
      this.calculateRentPricePerRow("", "all-row");
      this.calculateTotalMaxLoanFooter();
    },
    calculateTotalBrutoFooter() {
      this.identifier.brutoTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          this.identifier.brutoTotal =
            this.identifier.brutoTotal + parseFloat(data.bruto);
        }
      );
    },
    calculateTotalNetWeightFooter() {
      this.identifier.netWeightTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          this.identifier.netWeightTotal =
            this.identifier.netWeightTotal + parseFloat(data.netWeight);
        }
      );
    },
    calculateTotalSlteFooter(props) {
      this.identifier.slteTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var sltePrice = data.mortgage ? data.mortgage.sltePriceInput : 0;
          this.identifier.slteTotal =
            this.identifier.slteTotal + parseFloat(sltePrice);
          this.calculateTotalEstimatePerRow(props);
        }
      );
    },
    calculateTotalEstimatePriceFooter() {
      this.dataForm.estimatePrice = 0;
      this.identifier.estimatePriceTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var totalEstimate = data.totalEstimate;
          this.identifier.estimatePriceTotal =
            this.identifier.estimatePriceTotal + totalEstimate;
          this.dataForm.estimatePrice = this.identifier.estimatePriceTotal;
        }
      );
    },
    calculateTotalRentPriceFooter() {
      this.identifier.rentPriceTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var rentPrice = data.rentPrice;
          this.identifier.rentPriceTotal =
            this.identifier.rentPriceTotal + rentPrice;
        }
      );
      this.dataForm.feeRentNominal = this.identifier.rentPriceTotal;
      this.calculateFeeTotal();
    },
    calculateFeeTotal() {
      this.dataForm.feeTotal =
        this.dataForm.feeMaterai +
        this.dataForm.feeAdmin +
        this.dataForm.feeRentNominal;
    },
    calculateTotalMaxLoanFooter() {
      this.identifier.maxLoanTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var maxLoan = data.maxLoan;
          this.identifier.maxLoanTotal = this.identifier.maxLoanTotal + maxLoan;
        }
      );
      this.dataForm.nominalMortgage = this.identifier.maxLoanTotal;
      this.calculateNominalTotal();
      this.dataForm.feeRentPercent = 0;
      if (
        this.identifier.rentPriceTotal !== 0 &&
        this.identifier.maxLoanTotal
      ) {
        this.dataForm.feeRentPercent =
          (this.identifier.rentPriceTotal / this.identifier.maxLoanTotal) * 100;
      }
      this.calculateFeeTotal();
    },
    calculateNominalTotal() {
      this.dataForm.suggestedNominalTotal =
        this.dataForm.nominalMortgage -
        this.dataForm.feeRentNominal -
        this.dataForm.feeAdmin -
        this.dataForm.feeMaterai;
      this.dataForm.nominalTotal = this.dataForm.suggestedNominalTotal;
    },
    defaultDateNow() {
      this.dataForm.effectiveDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.akadDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.defaultDateNow();
    this.checkingMainId();
    this.getCifById();
    this.getReferenceMortgageRegisterNumber();
    this.getReferenceLoanAkadType();
    this.getReferenceLoan();
    // this.getReferenceCollector();
    this.getReferenceAccountOfficer();
    this.getReferencePurposeOpenAccount();
    this.getSavingAccountByCifId();
    this.getReferenceTimePeriode();
    this.getReferenceMortgage();
    this.getReferenceOfficeReferal();
  },
};
